import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import Dashboard from "./components/Dashboard";
import Map from "./components/Map";
import VaccineMap from "./components/vaccine/map/Map";
import PatientRegistration from "components/vaccine/queue/PatientRegistration";
import PatientQuestionnaire from "components/vaccine/queue/PatientQuestionnaire";
import FacilityRegister from "./components/registration/FacilityRegister";
import ProfessionalRegister from "./components/registration/ProfessionalRegister";
import { Login } from "./components/registration/Login";
import ResetPassword from "./components/ResetPassword";
import PrivacyPolicy from "components/registration/PrivacyPolicy";
import { AuthProvider } from "./components/context/Auth";
import styled, { ThemeProvider } from "styled-components";
import "./App.css";
import { loadReCaptcha } from "react-recaptcha-v3";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import theme from "./components/helpers/theme";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import InfoDump from "./components/InfoDump";
import TermsOfUse from "components/registration/TermsOfUse";
import Baa from "components/registration/baa";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import PatientRegister from "components/registration/PatientRegister";
import { ZipProvider } from "components/context/Zip";
import { LocationProvider } from "components/context/Location";
import Verify from "components/dashboard/Verify";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

if (process.env.NODE_ENV === "production")
  Sentry.init({
    dsn: "https://e60e858a354a42d88a5d71f742e1e77e@o384246.ingest.sentry.io/5215159",
  });

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#117ACA",
    },
    secondary: {
      main: "#1B4584",
    },
  },
});

function App() {
  const DoTracking = () => {
    var a = String(Math.random()) * 10000000000000;
    new Image().src = "https://pubads.g.doubleclick.net/activity;xsp=4736959;ord=" + a + "?";
    return <React.Fragment></React.Fragment>;
  };
  useEffect(() => {
    loadReCaptcha("6LdFylEaAAAAAFxT6Keio4PQU5sg1jhvn7BE5mPq", () => {});
  });

  const api_regex = /^\/api\/.*/;
  // if using "/api/" in the pathname, don't use React Router
  if (api_regex.test(window.location.pathname)) {
    return <div />; // must return at least an empty div
  } else {
    // use React Router
    return (
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <ZipProvider>
              <BrowserRouter>
                <div className="App">
                  <Routes>
                    <Route path="/info_dump" element={<InfoDump />} />

                    <Route
                      path="/dashboard/*"
                      element={
                        <Elements stripe={stripePromise}>
                          <LocationProvider>
                            <Dashboard />
                          </LocationProvider>
                        </Elements>
                      }
                    />
                    <Route path="/msi/register" element={<PatientRegister />} />
                    <Route path="/pro/register" element={<ProfessionalRegister />} />
                    <Route path="/register" element={<FacilityRegister />} />
                    <Route path="/login" element={<Login timestamp={new Date().toString()} />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/registration" element={<PatientRegistration />} />
                    <Route path="/vaccine/registration" element={<PatientRegistration vaccine />} />
                    <Route
                      path="/vaccine/questionnaire"
                      element={<PatientQuestionnaire vaccine />}
                    />
                    <Route path="/vaccine" element={<VaccineMap />} />
                    <Route path="/" element={<Map />} />

                    <Route path="/account" element={<Verify />} />

                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />
                    <Route path="/baa" element={<Baa />} />

                    {/* Catch-all route to map */}
                    <Route path="*" element={<Map />} />
                  </Routes>
                </div>
              </BrowserRouter>
            </ZipProvider>
          </AuthProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
