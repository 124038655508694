import React, { useState, useContext } from "react";
import Loading from "../Loading";
import { AuthContext } from "../context/Auth";
import { Navigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import firebaseApp from "../../firebase";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import LogoLarge from "../styled/LogoLarge";
import AuthWrapper from "./AuthWrapper";

import background from "../../bgLarge.jpg";
import { Box, Button, FormHelperText, Grid, Link, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import MuBox from "components/mui/MuBox";
import { ChatRounded, LocalHospitalRounded, MedicalServicesRounded } from "@mui/icons-material";

const LoginForm = ({ onLogin, loginFormOpen, setLoginFormOpen }) => {
  const isFunction = (functionToCheck) => {
    return functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";
  };
  const [firebaseErrors, setFirebaseErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = ({ email, password }) => {
    setLoading(true);

    // Create user and create database entry for user/booklist
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        onLogin();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        let errorMessage;
        switch (error.code) {
          case "auth/invalid-credential":
            errorMessage = "Your email address or password is incorrect. Please try again.";
            break;
          default:
            errorMessage = error.message;
        }

        setFirebaseErrors(errorMessage);
      });
  };

  if (!loginFormOpen)
    return (
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Already have an account?
        </Typography>
        <Button variant="contained" onClick={() => setLoginFormOpen(true)} sx={{ mb: 1 }}>
          Log In
        </Button>
      </Box>
    );

  if (loginFormOpen)
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          defaultValue={""}
          rules={{
            required: "Email is required.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address.",
            },
          }}
          render={({ field }) => (
            <TextField
              id="email"
              label="Email"
              type="email"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              fullWidth
              error={!!errors?.email}
              helperText={errors?.email?.message}
              sx={{ pb: 2 }}
              {...field}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          defaultValue={""}
          rules={{
            required: "Password is required.",
          }}
          render={({ field }) => (
            <TextField
              id="password"
              label="Password"
              type="password"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              fullWidth
              error={!!errors?.password}
              helperText={errors?.password?.message}
              sx={{ pb: 1 }}
              {...field}
            />
          )}
        />

        {firebaseErrors ? <FormHelperText error={true}>{firebaseErrors}</FormHelperText> : null}

        <LoadingButton
          type="submit"
          loading={loading}
          disabled={loading}
          variant="contained"
          fullWidth
          size="large"
          sx={{ mt: 2, mb: 3 }}
        >
          Log In
        </LoadingButton>
      </form>
    );
};

const Login = () => {
  const [loginFormOpen, setLoginFormOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  if (user !== null) {
    return <Loading />;
  }

  return (
    <AuthWrapper background={background}>
      <LogoLarge />

      <LoginForm
        onLogin={() => {
          return <Navigate to="/dashboard" />;
        }}
        loginFormOpen={loginFormOpen}
        setLoginFormOpen={setLoginFormOpen}
      />

      <Grid container sx={{ mb: 3 }}>
        <Grid item xs>
          <Link component={RouterLink} to="/" underline="none" variant="body2">
            Return to Map
          </Link>
        </Grid>

        {loginFormOpen ? (
          <Grid item>
            <Link component={RouterLink} to="/reset-password" underline="none" variant="body2">
              Reset password
            </Link>
          </Grid>
        ) : null}
      </Grid>

      <MuBox
        icon={<ChatRounded fontSize="large" sx={{ color: "#117aca" }} />}
        title="Create Patient Account"
        description="Talk through your symptoms and learn what might be causing them along with next steps to consider. Incorporate as much, or as little, personal health information into the conversation as you like."
        links={[
          {
            title: "Register Patient Account",
            onClick: () => {
              navigate("/msi/register");
            },
          },
        ]}
      />

      <MuBox
        icon={<MedicalServicesRounded fontSize="large" sx={{ color: "#117aca" }} />}
        title="Create Professional Account"
        description="Access Virtual Consults Across All Specialties. Includes nights, weekends, and holidays."
        links={[
          {
            title: "Register Professional Account",
            onClick: () => {
              navigate("/pro/register");
            },
          },
        ]}
      />

      <MuBox
        icon={<LocalHospitalRounded fontSize="large" sx={{ color: "#117aca" }} />}
        title="Organizational Enrollment"
        description="Enhance Your Visibility: Add your facility and its capabilities to the HLTHDSK Urgent Care Map—appropriate for Emergency Departments and any free-standing facility that accepts walk-in patients."
        links={[
          {
            title: "Register Facility Account",
            onClick: () => {
              navigate("/register");
            },
          },
        ]}
      />

      <Grid container sx={{ mb: 3 }}>
        <Grid item xs>
          <Link href="/terms-of-use" underline="none" variant="body2" target="_blank">
            Terms of Use
          </Link>
        </Grid>
        <Grid item>
          <Link href="/privacy-policy" underline="none" variant="body2" target="_blank">
            Privacy Policy
          </Link>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export { Login, LoginForm };
